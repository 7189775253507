<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Laba Rugi Perbandingan Periode</h4>
          </div>

          <!-- <button class="btn-add" @click="generatePdf">Export</button>
          <button
            class="btn-add"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            + Tambah
          </button> -->
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center justify-content-end">
            <button
              class="btn btn-save"
              :style="{ minWidth: '170px' }"
              @click="this.showModalRangeDouble = true"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.5082 2.125C12.3311 2.125 12.1469 2.19583 12.0124 2.33042L10.7161 3.62667L13.3724 6.28292L14.6686 4.98667C14.9449 4.71042 14.9449 4.26417 14.6686 3.98792L13.0111 2.33042C12.8694 2.18875 12.6924 2.125 12.5082 2.125ZM9.95819 6.38917L10.6099 7.04083L4.19236 13.4583H3.54069V12.8067L9.95819 6.38917ZM2.12402 12.2188L9.95819 4.38458L12.6144 7.04083L4.78027 14.875H2.12402V12.2188Z"
                  fill="#fff"
                />
              </svg>
              Ubah Parameter
            </button>
          </div>

          <iframe id="pdfFrame" v-if="!isLoading"></iframe>
          <div
            v-else
            :style="{
              height: '450px',
              background: '#8f8f8f',
              position: 'relative',
            }"
          >
            <loading
              :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }"
              :active="isLoading"
              :can-cancel="false"
              :on-cancel="onCancel"
              :is-full-page="fullPage"
              color="#68caf8"
              height="80"
              width="80"
            ></loading>
          </div>
        </div>
      </section>
    </div>
  </div>
  <modalRangeDouble
    :onPage="true"
    :show="showModalRangeDouble"
    :type="'profit_periode'"
    :isMerge="true"
    @hide="setHide()"
    @update="updateData($event)"
    :departement="optionDepartement"
    :isOther="true"
    :parameter="parameterReport"
  ></modalRangeDouble>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import modalRangeDouble from "../../components/report/modalRangeDouble.vue";
// import Select2 from "vue3-select2-component";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "../../../../assets/font/poppins-bold.js";
import "../../../../assets/font/poppins-regular.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Loading from "vue3-loading-overlay";

import $ from "jquery";
import { get_ReportProfitPeriode } from "../../../../actions/report/profit";
import { cksClient } from "../../../../helper";
import moment from "moment";
import "moment/locale/id";
import { get_ListDivisi } from "../../../../actions/master";
import { DATA } from "../../../../constans/Url";
// import { NONE } from "apisauce";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
    // Select2,
    Loading,
    modalRangeDouble,
  },
  data() {
    return {
      showModalRangeDouble: false,
      fullPage: true,
      isLoading: true,
      thr: "thr",
      bonus: "bonus",
      gaji: "gaji",
      canvas: null,
      optionDepartement: [],
      dataEstimatedAccount: [],
      parameterReport: {
        id_divisi: this.$route.query.id_divisi,
        first_periode: this.$route.query.first_periode,
        second_periode: this.$route.query.second_periode,
        merge: this.$route.query.merge,
        isAll: this.$route.query.isAll,
      },
      dataCompany: {
        nama: "",
        divisi: "",
        alamat: "",
        email: "",
        no_telp: "",
        logo: "",
      },
      dataReport: [],
      dataColumn: [],
      titleDate: "",
      titleDateCol: "",
      namaReport: "",
    };
  },

  mounted() {
    if (
      this.parameterReport.first_periode.length > 0 &&
      this.parameterReport.second_periode.length > 0
    ) {
      this.getData();
    }
    // this.tooltip();
    // this.exportPdf();
  },

  created() {
    this.getDivisi();
  },

  methods: {
    getData() {
      var data = {
        ...this.parameterReport,
      };
      data.date_start = this.parameterReport.first_periode;
      data.date_end = this.parameterReport.second_periode;
      data.id_company = cksClient().get("_account").id_company;
      this.isLoading = true;
      get_ReportProfitPeriode(
        data,
        async (res) => {
          this.dataColumn = [
            {
              title: "Deskripsi",
              dataKey: "akun",
            },
            {
              title: this.convertDateRange(
                res.first_periode[0],
                res.first_periode[1]
              ),
              dataKey: "first",
            },
            {
              title: this.convertDateRange(
                res.second_periode[0],
                res.second_periode[1]
              ),
              dataKey: "second",
            },
            {
              title: "Variance",
              dataKey: "nilai",
            },
          ];

          const {
            pendapatan,
            pendapatan_lainnya,
            beban_pokok_penjualan,
            beban_operasional,
            beban_lainnya,
            pajak_penghasilan,
            divisi,
            company,
            // first_periode,
            // second_periode,
          } = res;

          this.dataCompany = company;
          this.dataCompany.divisi = divisi;
          if (divisi) {
            this.namaReport = divisi;
          } else {
            this.namaReport = cksClient().get("_account").nama_company;
          }
          this.dataReport = [];
          var data = [];
          data.push({
            akun: "PENDAPATAN",
            padding: 0,
            nilai: "",
            bold: true,
          });
          var yearArray = ["first", "second"];
          var jumlah_pendapatan = 0;
          for (const key in pendapatan) {
            if (Object.hasOwnProperty.call(pendapatan, key)) {
              var element = pendapatan[key];
              element.padding = 1 * element.sub_level;
              var nilai = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el == "first") {
                    nilai += element[el] ? parseInt(element[el]) : 0;
                  } else {
                    nilai -= element[el] ? parseInt(element[el]) : 0;
                  }
                  element[el] = element[el] ? this.formatMoney(element[el]) : 0;
                }
              }
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (element.sub_level == "1" || element.sub_level == 1)
              ) {
                element.color = "#FF3D3D";
              }
              element.nilai = this.formatMoney(nilai);
              if (element.sub_level === 1 || element.sub_level === "1") {
                jumlah_pendapatan += element.nilai
                  ? parseInt(element.nilai.toString().split(".").join(""))
                  : 0;
              }
              data.push(element);
            }
          }
          var arryJmlPendapatan = await this.countJumlahPendapatan(pendapatan);
          data.push({
            akun: "Jumlah Pendapatan",
            padding: 0,
            nilai: this.formatMoney(parseInt(jumlah_pendapatan)),
            ...arryJmlPendapatan,
            bold: true,
          });

          data.push(
            {
              akun: "",
              padding: 0,
              nilai: "",
              bold: true,
            },
            {
              akun: "BEBAN USAHA",
              padding: 0,
              nilai: "",
              bold: true,
            }
          );
          var jumlah_beban_pokok = 0;
          for (const key in beban_pokok_penjualan) {
            if (Object.hasOwnProperty.call(beban_pokok_penjualan, key)) {
              var elbeban = beban_pokok_penjualan[key];
              elbeban.padding = 1 * elbeban.sub_level;
              var nilaibeban = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el == "first") {
                    nilaibeban += elbeban[el] ? parseInt(elbeban[el]) : 0;
                  } else {
                    nilaibeban -= elbeban[el] ? parseInt(elbeban[el]) : 0;
                  }
                  elbeban[el] = elbeban[el] ? this.formatMoney(elbeban[el]) : 0;
                }
              }
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elbeban.sub_level == "1" || elbeban.sub_level == 1)
              ) {
                elbeban.color = "#FF3D3D";
              }
              if (elbeban.sub_level === 1 || elbeban.sub_level === "1") {
                jumlah_beban_pokok += nilaibeban
                  ? parseInt(nilaibeban.toString().split(".").join(""))
                  : 0;
              }
              elbeban.nilai = this.formatMoney(nilaibeban);
              elbeban.nilai = elbeban.nilai
                ? this.formatMoney(elbeban.nilai)
                : 0;
              data.push(elbeban);
            }
          }

          var arryJmlPokokPenjualan = await this.countJumlahPendapatan(
            beban_pokok_penjualan
          );

          data.push(
            {
              akun: "Jumlah Beban Usaha",
              padding: 0,
              nilai: this.formatMoney(parseInt(jumlah_beban_pokok)),
              ...arryJmlPokokPenjualan,
              bold: true,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
              bold: true,
            },
            {
              akun: "LABA KOTOR",
              padding: 0,
              nilai: this.formatMoney(
                parseInt(jumlah_pendapatan) - parseInt(jumlah_beban_pokok)
              ),
              first: this.formatMoney(
                parseInt(
                  arryJmlPendapatan["first"].toString().split(".").join("")
                ) -
                  parseInt(
                    arryJmlPokokPenjualan["first"]
                      .toString()
                      .split(".")
                      .join("")
                  )
              ),
              second: this.formatMoney(
                parseInt(
                  arryJmlPendapatan["second"].toString().split(".").join("")
                ) -
                  parseInt(
                    arryJmlPokokPenjualan["second"]
                      .toString()
                      .split(".")
                      .join("")
                  )
              ),
              bold: true,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
              bold: true,
            },
            {
              nama: "Beban Operasional",
              padding: 0,
              nilai: "",
              bold: true,
            }
          );

          var jumlah_beban_operasional = 0;
          for (const key in beban_operasional) {
            if (Object.hasOwnProperty.call(beban_operasional, key)) {
              var elbebanops = beban_operasional[key];
              elbebanops.padding = 1 * elbebanops.sub_level;
              var nilaiops = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el == "first") {
                    nilaiops += elbebanops[el] ? parseInt(elbebanops[el]) : 0;
                  } else {
                    nilaiops -= elbebanops[el] ? parseInt(elbebanops[el]) : 0;
                  }
                  elbebanops[el] = elbebanops[el]
                    ? this.formatMoney(elbebanops[el])
                    : 0;
                }
              }
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elbebanops.sub_level == "1" || elbebanops.sub_level == 1)
              ) {
                elbebanops.color = "#FF3D3D";
              }
              if (elbebanops.sub_level === 1 || elbebanops.sub_level === "1") {
                jumlah_beban_operasional += nilaiops;
              }
              elbebanops.nilai = this.formatMoney(nilaiops);
              elbebanops.nilai = elbebanops.nilai
                ? this.formatMoney(elbebanops.nilai)
                : 0;
              data.push(elbebanops);
            }
          }

          var arryJmlBebanOperssional = await this.countJumlahPendapatan(
            beban_operasional
          );

          data.push(
            {
              akun: "Jumlah Beban Operasional",
              padding: 0,
              nilai: this.formatMoney(jumlah_beban_operasional),
              ...arryJmlBebanOperssional,
              bold: true,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
              bold: true,
            },
            {
              akun: "PENDAPATAN OPERASIONAL",
              padding: 0,
              nilai: this.formatMoney(
                parseInt(jumlah_pendapatan) -
                  parseInt(jumlah_beban_pokok) -
                  parseInt(jumlah_beban_operasional)
              ),
              first: this.formatMoney(
                this.summaryArray({
                  pendapatan: arryJmlPendapatan["first"],
                  beban_pokok: arryJmlPokokPenjualan["first"],
                  beban_operasional: arryJmlBebanOperssional["first"],
                })
              ),
              second: this.formatMoney(
                this.summaryArray({
                  pendapatan: arryJmlPendapatan["second"],
                  beban_pokok: arryJmlPokokPenjualan["second"],
                  beban_operasional: arryJmlBebanOperssional["second"],
                })
              ),
              bold: true,
            },
            {
              akun: "",
              padding: "",
              nilai: "",
              bold: true,
            },
            {
              akun: "PENDAPATAN DAN BEBAN NON OPERASIONAL",
              padding: 0,
              nilai: "",
              bold: true,
            },
            {
              akun: "Pendapatan Non Operasional",
              padding: 1,
              nilai: "",
              bold: true,
            }
          );

          var jumlah_pendapatan_lainnya = 0;
          for (const key in pendapatan_lainnya) {
            if (Object.hasOwnProperty.call(pendapatan_lainnya, key)) {
              var elpen = pendapatan_lainnya[key];
              elpen.padding = 2 * elpen.sub_level;
              var nilaipen = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el == "first") {
                    nilaipen += elpen[el] ? parseInt(elpen[el]) : 0;
                  } else {
                    nilaipen -= elpen[el] ? parseInt(elpen[el]) : 0;
                  }
                  elpen[el] = elpen[el] ? this.formatMoney(elpen[el]) : 0;
                }
              }
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elpen.sub_level == "1" || elpen.sub_level == 1)
              ) {
                elpen.color = "#FF3D3D";
              }
              if (elpen.sub_level === 1 || elpen.sub_level === "1") {
                jumlah_pendapatan_lainnya += nilaipen
                  ? parseInt(nilaipen.toString().split(".").join(""))
                  : 0;
              }
              elpen.nilai = this.formatMoney(nilaipen);
              elpen.nilai = elpen.nilai ? this.formatMoney(elpen.nilai) : 0;
              data.push(elpen);
            }
          }

          var arryJmlPendapatanLainnya = await this.countJumlahPendapatan(
            pendapatan_lainnya
          );

          data.push(
            {
              akun: "Jumlah Pendapatan Non Operasional",
              padding: 1,
              nilai: this.formatMoney(jumlah_pendapatan_lainnya),
              ...arryJmlPendapatanLainnya,
              bold: true,
            },
            {
              akun: "Beban Non Operasional",
              padding: 1,
              nilai: "",
              bold: true,
            }
          );

          var jumlah_beban_lainnya = 0;
          for (const key in beban_lainnya) {
            if (Object.hasOwnProperty.call(beban_lainnya, key)) {
              var elbebanl = beban_lainnya[key];

              elbebanl.padding = 2 * elbebanl.sub_level;
              var nilaibebanl = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el == "first") {
                    nilaibebanl += elbebanl[el] ? parseInt(elbebanl[el]) : 0;
                  } else {
                    nilaibebanl -= elbebanl[el] ? parseInt(elbebanl[el]) : 0;
                  }
                  elbebanl[el] = elbebanl[el]
                    ? this.formatMoney(elbebanl[el])
                    : 0;
                }
              }
              if (elbebanl.sub_level === 1 || elbebanl.sub_level === "1") {
                jumlah_beban_lainnya += nilaibebanl
                  ? parseInt(nilaibebanl.toString().split(".").join(""))
                  : 0;
              }
              if (
                !divisi &&
                this.parameterReport.isAll != "true" &&
                this.parameterReport.isAll != true &&
                (elbebanl.sub_level == "1" || elbebanl.sub_level == 1)
              ) {
                elbebanl.color = "#FF3D3D";
              }
              elbebanl.nilai = this.formatMoney(nilaibebanl);
              elbebanl.nilai = elbebanl.nilai
                ? this.formatMoney(elbebanl.nilai)
                : 0;
              data.push(elbebanl);
            }
          }

          var arryJmlBebanLainnya = await this.countJumlahPendapatan(
            beban_lainnya
          );

          var arryJmlPajakPenghasilan = await this.countJumlahPendapatan(
            pajak_penghasilan
          );

          var jumlah_pajak_penghasilan = 0;
          for (const key in pajak_penghasilan) {
            if (Object.hasOwnProperty.call(pajak_penghasilan, key)) {
              var elPajak = pajak_penghasilan[key];
              var nilaiPajak = 0;
              for (const i in yearArray) {
                if (Object.hasOwnProperty.call(yearArray, i)) {
                  const el = yearArray[i];
                  if (el == "first") {
                    nilaiPajak += elPajak[el] ? parseInt(elPajak[el]) : 0;
                  } else {
                    nilaiPajak -= elPajak[el] ? parseInt(elPajak[el]) : 0;
                  }
                  elPajak[el] = elPajak[el] ? this.formatMoney(elPajak[el]) : 0;
                }
              }
              if (elPajak.sub_level === 1 || elPajak.sub_level === "1") {
                jumlah_pajak_penghasilan += nilaiPajak
                  ? parseInt(nilaiPajak.toString().split(".").join(""))
                  : 0;
              }
            }
          }

          var laba =
            parseInt(jumlah_pendapatan) -
            parseInt(jumlah_beban_pokok) -
            parseInt(jumlah_beban_operasional) +
            parseInt(jumlah_pendapatan_lainnya) -
            parseInt(jumlah_beban_lainnya);
          data.push(
            {
              akun: "Jumlah Beban Non Operasional",
              padding: 1,
              nilai: this.formatMoney(jumlah_beban_lainnya),
              ...arryJmlBebanLainnya,
              bold: true,
            },
            {
              akun: "Jumlah Pendapatan dan Beban Non Operasional",
              padding: 0,
              nilai: this.formatMoney(
                jumlah_pendapatan_lainnya - jumlah_beban_lainnya
              ),
              first: this.formatMoney(
                this.moneyToInt(arryJmlPendapatanLainnya["first"]) -
                  this.moneyToInt(arryJmlBebanLainnya["first"])
              ),
              second: this.formatMoney(
                this.moneyToInt(arryJmlPendapatanLainnya["second"]) -
                  this.moneyToInt(arryJmlBebanLainnya["second"])
              ),
              bold: true,
            },
            {
              akun: "",
              padding: 0,
              nilai: "",
            },
            {
              akun: "LABA BERSIH (Sebelum Pajak)",
              padding: 0,
              nilai: this.formatMoney(laba),
              first: this.formatMoney(
                this.summaryArray({
                  pendapatan: arryJmlPendapatan["first"],
                  beban_pokok: arryJmlPokokPenjualan["first"],
                  beban_operasional: arryJmlBebanOperssional["first"],
                }) +
                  this.moneyToInt(arryJmlPendapatanLainnya["first"]) -
                  this.moneyToInt(arryJmlBebanLainnya["first"])
              ),
              second: this.formatMoney(
                this.summaryArray({
                  pendapatan: arryJmlPendapatan["second"],
                  beban_pokok: arryJmlPokokPenjualan["second"],
                  beban_operasional: arryJmlBebanOperssional["second"],
                }) +
                  this.moneyToInt(arryJmlPendapatanLainnya["second"]) -
                  this.moneyToInt(arryJmlBebanLainnya["second"])
              ),
              bold: true,
            },
            {
              akun: "Jumlah Pajak Penghasilan",
              padding: 0,
              // bold:
              nilai: this.formatMoney(jumlah_pajak_penghasilan),
              ...arryJmlPajakPenghasilan,
            },
            {
              akun: "LABA BERSIH (Setelah Pajak)",
              padding: 0,
              nilai: this.formatMoney(laba - jumlah_pajak_penghasilan),
              first: this.formatMoney(
                this.summaryArray({
                  pendapatan: arryJmlPendapatan["first"],
                  beban_pokok: arryJmlPokokPenjualan["first"],
                  beban_operasional: arryJmlBebanOperssional["first"],
                }) +
                  this.moneyToInt(arryJmlPendapatanLainnya["first"]) -
                  this.moneyToInt(arryJmlPajakPenghasilan["first"]) -
                  this.moneyToInt(arryJmlBebanLainnya["first"])
              ),
              second: this.formatMoney(
                this.summaryArray({
                  pendapatan: arryJmlPendapatan["second"],
                  beban_pokok: arryJmlPokokPenjualan["second"],
                  beban_operasional: arryJmlBebanOperssional["second"],
                }) +
                  this.moneyToInt(arryJmlPendapatanLainnya["second"]) -
                  this.moneyToInt(arryJmlPajakPenghasilan["second"]) -
                  this.moneyToInt(arryJmlBebanLainnya["second"])
              ),
              bold: true,
            }
          );

          this.dataReport = data;
          this.convertDate(res.first_periode, res.second_periode);
          this.isLoading = false;
          this.generatePdf();
        },
        (err) => {
          console.log(err);
        }
      );
    },

    moneyToInt(string) {
      return string ? parseInt(string.toString().split(".").join("")) : 0;
    },

    convertDateRange(date_start, date_end) {
      var start = moment(date_start);
      var end = moment(date_end);
      if (start.format("YYYY-MM") == end.format("YYYY-MM") && start.isValid()) {
        return `${start.format("DD")} s/d ${end.format("DD MMM YYYY")}`;
      } else if (
        start.format("YYYY") == end.format("YYYY") &&
        start.isValid()
      ) {
        return `${start.format("DD MMM")} s/d ${end.format("DD MMM YYYY")}`;
      } else {
        return `${start.format("DD MMM YYYY")} dan ${end.format(
          "DD MMM YYYY"
        )}`;
      }
    },

    convertDate(date_start, date_end) {
      // var start = moment(date_start);
      // var end = moment(date_end);
      this.titleDate = `Tgl. ${this.convertDateRange(
        date_start[0],
        date_start[1]
      )} dan ${this.convertDateRange(date_end[0], date_end[1])}`;
      // if (start.format("YYYY-MM") == end.format("YYYY-MM") && start.isValid()) {
      //   this.titleDate = `Per Tgl. ${start.format("DD MMM")} dan ${end.format(
      //     "DD MMM YYYY"
      //   )}`;
      //   this.titleDateCol = `${start.format("DD")} dan ${end.format(
      //     "DD MMM YYYY"
      //   )}`;
      // } else if (
      //   start.format("YYYY") != end.format("YYYY") &&
      //   start.isValid()
      // ) {
      //   this.titleDate = `Per Tgl. ${start.format("DD MMM")} dan ${end.format(
      //     "DD MMM YYYY"
      //   )}`;
      //   this.titleDateCol = `${start.format("DD MMM")} dan ${end.format(
      //     "DD MMM YYYY"
      //   )}`;
      // } else {
      //   this.titleDate = `Per Tgl. ${start.format(
      //     "DD MMM YYYY"
      //   )} dan ${end.format("DD MMM YYYY")}`;
      //   this.titleDateCol = `${start.format("DD MMM YYYY")} dan ${end.format(
      //     "DD MMM YYYY"
      //   )}`;
      // }
    },

    summaryArray(obj, type = "") {
      var nilai = 0;
      var i = 0;
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const element = obj[key];
          if (type == "beban") {
            if (i == 0) {
              nilai = parseInt(element.toString().split(".").join(""));
            } else if (
              parseInt(element.toString().split(".").join("")) >= 0 &&
              nilai != 0
            ) {
              nilai -= parseInt(element.toString().split(".").join(""));
            } else {
              nilai += parseInt(element.toString().split(".").join(""));
            }
          } else {
            if (i == 0) {
              nilai = parseInt(element.toString().split(".").join(""));
            } else if (
              parseInt(element.toString().split(".").join("")) >= 0 &&
              nilai != 0
            ) {
              nilai -= parseInt(element.toString().split(".").join(""));
            } else {
              nilai += parseInt(element.toString().split(".").join(""));
            }
          }
          i++;
        }
      }
      return nilai;
    },

    countJumlahPendapatan(data) {
      var object = {
        first: 0,
        second: 0,
      };

      for (const i in data) {
        const element = data[i];
        for (const key in element) {
          if (
            Object.hasOwnProperty.call(element, key) &&
            key != "nilai" &&
            key != "akun" &&
            key != "padding"
          ) {
            const el = element[key];
            if (element.sub_level === 1 || element.sub_level === "1") {
              object[key] += el
                ? parseInt(el.toString().split(".").join(""))
                : 0;
            }
          }
        }
      }

      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          object[key] = object[key] ? this.formatMoney(object[key]) : 0;
        }
      }

      return object;
    },

    setHide() {
      this.showModalRangeDouble = false;
    },

    updateData(event) {
      this.parameterReport = event;
      this.getData();
    },

    getDivisi() {
      get_ListDivisi(
        {
          id_company: cksClient().get("_account").id_company,
        },
        (res) => {
          this.optionDepartement = res.list;
        }
      );
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    convertImgToBase64(url) {
      return new Promise((resolve) => {
        var image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#6BACCC"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    },

    splitTextIntoTwoArrays(text) {
      // Step 1: Split the text into words using whitespace as the delimiter
      const wordsArray = text.split(/\s+/);

      // Step 2: Determine the size of each array
      const totalWords = wordsArray.length;
      const arraySize = Math.ceil(totalWords / 2);

      // Step 3: Divide the words into three arrays
      const firstArray = wordsArray.slice(0, arraySize);
      // const secondArray = wordsArray.slice(arraySize, arraySize * 2);
      const secondArray = wordsArray.slice(arraySize);

      return [firstArray, secondArray];
    },

    async generatePdf() {
      const doc = new jsPDF("l", "mm", "a4");
      var header = "img/bg-header.png";
      doc.addImage(header, "png", 298, -35, 300, 2500 / 72, null, null, 180);
      var logo = await this.convertImgToBase64(
        DATA.COMPANY(this.dataCompany.logo)
      );
      doc.addImage(logo, "png", 13, 6, 23, 23, null, null, 0);

      doc.setFont("Poppins-Bold").setFontSize(15);
      doc.setTextColor(255, 255, 255);
      doc.text(40, 15, `${this.dataCompany.nama}`, {
        align: "left",
      });
      doc.setFont("Poppins-Regular").setFontSize(10);
      doc.setTextColor(255, 255, 255);
      doc.text(
        40,
        22,
        `${
          this.dataCompany.divisi
            ? this.dataCompany.divisi
            : this.dataCompany.email
        }`,
        {
          align: "left",
        }
      );

      doc.setFontSize(10);
      doc.setFont("Poppins-Regular");
      doc.splitTextToSize(this.dataCompany.alamat, 10);

      const [array1, array2] = this.splitTextIntoTwoArrays(
        this.dataCompany.alamat,
        2
      );

      doc.text(288, 10, array1.join(" "), {
        align: "right",
      });
      doc.text(288, 16, array2.join(" "), {
        align: "right",
      });
      doc.text(288, 22, this.dataCompany.no_telp, {
        align: "right",
      });
      doc.text(288, 28, this.dataCompany.divisi ? this.dataCompany.email : "", {
        align: "right",
      });
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      // doc.text(145, 45, this.namaReport, "center");
      doc.setFontSize(16);
      doc.text(145, 45, `Laba/Rugi (Perbandingan Periode)`, {
        align: "center",
      });
      doc.setProperties({
        title: `Laba/Rugi (Perbandingan Periode)`,
      });
      doc.setFontSize(10);
      doc.text(145, 53, this.titleDate, "center");
      var blankTable = {
        columns: [
          {
            title: "",
            dataKey: "id",
          },
        ],
        rows: [
          {
            id: "",
          },
        ],
      };

      doc.autoTable(blankTable.columns, blankTable.rows, {
        margin: {
          top: 45,
          bottom: 80,
        },
        theme: "plain",
      });
      var tableData = {
        columns: this.dataColumn,
        rows: this.dataReport,
      };
      doc.autoTable(tableData.columns, tableData.rows, {
        showHead: "everyPage",
        didParseCell: function (data) {
          if (data.column.index === 0) {
            var padding = data.row.raw.padding
              ? parseInt(data.row.raw.padding)
              : 1;
            data.cell.styles.cellPadding = {
              left: 3 * padding,
            };
          }

          if (data.row.raw.bold && data.column.index === 0) {
            data.cell.styles.fontStyle = "bold";
          }
          if (data.row.section === "head") {
            data.cell.styles.textColor = "#324C8F";
            // data.cell.styles.halign = "center";
            if (data.column.dataKey !== "akun") {
              data.cell.styles.halign = "right";
            }
          }

          if (data.row.raw.color) {
            data.cell.styles.textColor = data.row.raw.color;
          }
        },

        columnStyles: {
          0: {
            cellPadding: 1,
          },
          1: {
            halign: "right",
          },
          2: {
            halign: "right",
          },
          3: {
            halign: "right",
          },
          4: {
            halign: "right",
          },
          5: {
            halign: "right",
          },
        },
        margin: {
          top: 10,
          bottom: 60,
        },
        theme: "plain",
      });

      // PAGE NUMBERING
      // Add Page number at bottom-right
      // Get the number of pages
      const pageCount = doc.internal.getNumberOfPages();
      // var width = doc.internal.pageSize.getWidth();
      // var height = doc.internal.pageSize.getHeight();

      // For each page, print the page number and the nilai pages
      for (var i = 1; i <= pageCount; i++) {
        // Go to page i
        doc.setPage(i);

        //Print Page 1 of 4 for example

        var img = new Image();
        img.src = "img/line.png";
        doc.addImage(img, "png", 270, 160, 257, 1 / 72, null, null, 180);

        doc.text(105, 257, "Finanta Accounting System Report", "center");

        doc.text(
          "Page " + String(i) + " of " + String(pageCount),
          290 - 20,
          210 - 30,
          null,
          null,
          "right"
        );

        doc.text(
          `Tercetak pada ${moment().format("DD MMMM YYYY - HH:mm")}`,
          205 - 192,
          210 - 30,
          null,
          null,
          "left"
        );
      }

      setTimeout(() => {
        var iframe = document.getElementById("pdfFrame");
        iframe.src = doc.output("bloburi", {
          filename: `Laba/Rugi ${this.namaReport} (Perbandingan Periode).pdf`,
        });
      }, 500);
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(payroll) {
      const getPayroll = payroll;

      if (getPayroll == "bonus") {
        this.$router.push("/data-payroll/detail-bonus");
      }
      if (getPayroll == "gaji") {
        this.$router.push("/data-payroll/detail-gaji");
      }
      if (getPayroll == "thr") {
        this.$router.push("/data-payroll/detail-thr");
      }

      $('[data-toggle="tooltip"]').tooltip("hide");
    },
  },
};
</script>

<style scoped>
.card-table {
  margin-top: 0px !important;
}

.btn-gaji {
  background-color: #68caf8;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-bonus {
  background-color: #f8ce3a;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-thr {
  background-color: #ff5252;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.dropdown-menu {
  background: transparent !important;
  box-shadow: none !important;
  width: 102px !important;
  min-width: unset;
}

.dropdown-item:nth-child(2) {
  margin-top: 6px;
}

.dropdown-item:nth-child(3) {
  margin-top: 6px;
}

iframe {
  z-index: 1000;
  width: 100%;
  height: 800px;
}
</style>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>